import { request } from 'api/api';
import { API } from 'config/api/apiUrls';
import { useMutation, useQuery } from 'react-query';

interface IpostCountryRetailerConfigurations {
  list: object[];
  country_code: string;
}
interface IOnboardCountry {
  country_code: string[];
  onboarded_status: string;
  is_sfdc_auto_fetch_enabled?: boolean;
}

interface ISequence {
  countryId: string;
  program_type: string;
  status: object;
}

interface IpostRolesAndFeatures {
  countryId: string;
  roles: object[];
}

interface IpostSfdcQueryCatalogues {
  data: object[];
  country_code: string;
}

interface IPropsSFDCToggleForCountry {
  countryData: {
    country_code: string[];
    onboarded_status: string;
    is_sfdc_auto_fetch_enabled?: boolean;
  };
  sequencePayload?: any;
  SfdcQueryCataloguesPayload?: any;
}

export const GetAllCountryList = async (type: string) => {
  const res = await request.get(API.super_admin.all_country_list(type));
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
    success: data.success,
  };
};

export const useGetAllCountryList = (type: string, queryProps = {}) =>
  useQuery(['fetchCountryList'], () => GetAllCountryList(type), {
    refetchOnWindowFocus: false,
    ...queryProps,
  });

export const GetAllSfdcQueryCatalogues = async (countryCode: string) => {
  const res = await request.get(API.super_admin.getSfdcQueryCatalogues(countryCode));
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
    success: data.success,
  };
};

export const useGetSfdcQueryCatalogues = (countryCode: string, queryProps = {}) =>
  useQuery(['Sfdc_Query_catalogues'], () => GetAllSfdcQueryCatalogues(countryCode), {
    refetchOnWindowFocus: false,
    ...queryProps,
  });

export const GetCountryRoles = async () => {
  const res = await request.get(API.super_admin.country_roles());
  const data = res.data;
  return { data: data.data, status: res.status, resultCode: data.resultCode };
};
export const OnboardCountry = async (payload: IOnboardCountry) => {
  const res = await request.post(API.super_admin.onboard_country, payload);
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
    success: data.success,
  };
};
export const Sequence = async (payload: ISequence) => {
  const res = await request.post(API.super_admin.sequence, payload);
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
    success: data.success,
  };
};
export const postSfdcQueryCatalogues = async (payload: IpostSfdcQueryCatalogues) => {
  const res = await request.post(API.super_admin.postSfdcQueryCatalogues, payload);
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
    success: data.success,
  };
};
export const postRolesAndFeatures = async (payload: IpostRolesAndFeatures) => {
  const res = await request.post(API.super_admin.rolesAndFeatures, payload);
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
    success: data.success,
  };
};
export const putRolesAndFeatures = async (payload: IpostRolesAndFeatures) => {
  const res = await request.post(API.super_admin.rolesAndFeatures, payload);
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
    success: data.success,
  };
};
export const getRolesAndFeatures = async (countryId: string) => {
  const res = await request.get(API.super_admin.getRolesAndFeatures(countryId));
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
    success: data.success,
    key: 'RolesAndFeatures',
  };
};
export const postCountryConfigurations = async ({
  countryId,
  payload,
}: {
  countryId: string;
  payload: any;
}) => {
  const res = await request.post(API.super_admin.countryConfigurations(countryId), payload);
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
    success: data.success,
  };
};
export const getCountryConfigurations = async (countryId: string) => {
  const res = await request.get(API.super_admin.countryConfigurations(countryId));
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
    success: data.success,
    key: 'countryConfiguration',
  };
};
export const putCountryConfigurations = async ({
  countryId,
  configKey,
  payload,
}: {
  countryId: string;
  configKey: string;
  payload: any;
}) => {
  const res = await request.put(
    API.super_admin.putCountryConfigurations(countryId, configKey),
    payload
  );
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
    success: data.success,
  };
};

export const postCountryRetailerConfigurations = async (
  payload: IpostCountryRetailerConfigurations
) => {
  const res = await request.post(API.super_admin.retailerConfigurations, payload);
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
    success: data.success,
  };
};

export const putCountryRetailerConfigurations = async (
  payload: IpostCountryRetailerConfigurations
) => {
  const res = await request.put(API.super_admin.retailerConfigurations, payload);
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
    success: data.success,
  };
};

export const getCountryRetailerConfigurations = async (countryId: string, type: string) => {
  const res = await request.get(API.super_admin.getRetailerConfigurations(countryId, type));
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
    success: data.success,
    key: 'RetailerConfiguration',
  };
};

export const deleteCountry = async (countryId: string) => {
  const res = await request.delete(API.super_admin.delete_country(countryId));
  const data = res.data;
  return {
    data: data.data,
    success: data.success,
    message: data.message,
  };
};

export const useDeleteCountry = () => useMutation(deleteCountry);

export const useSaveCountryDetails = () =>
  useMutation((props: any) => {
    const arrayApi = [
      OnboardCountry(props.countryData),
      postCountryConfigurations(props.unitsAndFormats),
      postRolesAndFeatures(props.rolesAndFeature),
      postCountryRetailerConfigurations(props.retailerList),
      postCountryConfigurations(props.paymentDetails),
      postCountryConfigurations(props.supportDetails),
      postCountryConfigurations(props.faqVideos),
      postCountryConfigurations(props.faqLink),
      postCountryConfigurations(props.campaignSelectionData),
      postCountryConfigurations(props.growerInstructions),
    ];
    props.sequencePayload && arrayApi.push(Sequence(props.sequencePayload));
    props.SfdcQueryCataloguesPayload &&
      arrayApi.push(postSfdcQueryCatalogues(props.SfdcQueryCataloguesPayload));
    return Promise.all(arrayApi);
  });

export const useEditSaveCountryDetails = (faqKeyFlag = false, instructionDetailsExists = false) =>
  useMutation((props: any) => {
    const arrayApi = [
      OnboardCountry(props.countryData),
      putCountryConfigurations(props.unitsAndFormats),
      putRolesAndFeatures(props.rolesAndFeature),
      putCountryRetailerConfigurations(props.retailerList),
      putCountryConfigurations(props.paymentDetails),
      putCountryConfigurations(props.supportDetails),
      //existing country doesn't have key for faq sections, so need post api instead of put
      faqKeyFlag
        ? putCountryConfigurations(props.faqVideos)
        : postCountryConfigurations(props.faqVideos),
      faqKeyFlag
        ? putCountryConfigurations(props.faqLink)
        : postCountryConfigurations(props.faqLink),
      instructionDetailsExists
        ? putCountryConfigurations(props.campaignSelectionData)
        : postCountryConfigurations(props.campaignSelectionData),
      instructionDetailsExists
        ? putCountryConfigurations(props.growerInstructions)
        : postCountryConfigurations(props.growerInstructions),
    ];
    props.sequencePayload && arrayApi.push(Sequence(props.sequencePayload));
    props.SfdcQueryCataloguesPayload &&
      arrayApi.push(postSfdcQueryCatalogues(props.SfdcQueryCataloguesPayload));
    return Promise.all(arrayApi);
  });

export const useSFDCToggleForCountry = () =>
  useMutation((props: IPropsSFDCToggleForCountry) => {
    const arrayApi = [OnboardCountry(props.countryData)];
    props.sequencePayload && arrayApi.push(Sequence(props.sequencePayload));
    props.SfdcQueryCataloguesPayload &&
      arrayApi.push(postSfdcQueryCatalogues(props.SfdcQueryCataloguesPayload));
    return Promise.all(arrayApi);
  });

export const useGetCountryConfiguration = (countryId, type = '', queryProps) =>
  useQuery(
    ['getCountryConfig'],
    () =>
      Promise.all([
        getCountryConfigurations(countryId),
        getRolesAndFeatures(countryId),
        getCountryRetailerConfigurations(countryId, type),
      ]),
    {
      cacheTime: 0,
      ...queryProps,
    }
  );

export const postContractsDetails = async (countryId: string, payload: any) => {
  const res = await request.post(`${API.contracts}/${countryId}`, JSON.stringify(payload));
  const data = res.data;
  return {
    data: data.data,
    status: res.status,
    resultCode: data.resultCode,
  };
};

export const getContractDetails = async (countryId: string) => {
  const res = await request.get(`${API.contracts}/${countryId}`);
  const data = await res.data;
  return { data: data.data, status: res.status, resultCode: data.resultCode };
};

export const useGetContractDetails = (countryId: string, queryProps = {}) =>
  useQuery(['getContractDetails'], () => getContractDetails(countryId), {
    ...queryProps,
  });

export const deleteContractDetails = async (countryId: string, payload: any) => {
  const res = await request.delete(`${API.contracts}/${countryId}`, { data: payload });
  const data = res.data;
  return { data: data.data, status: res.status, resultCode: data.resultCode };
};

import { Tooltip, Typography } from 'antd';
import { ITimeZone, formatDate, getTimeZone } from 'core/common-methods';
import {
  countryCodesFunc,
  countryDetailsFromStorage,
  getCountryCode,
} from 'core/utils/countryUtils';
import { getFromLocalStorage } from 'core/utils/localStorage';

import canolaRapeseedGreenIcon from 'assets/images/CanolaGreen.svg';

import canolaRapeseedGreyIcon from 'assets/images/CanolaRapeseedIcon.svg';
import cornGreyIcon from 'assets/images/CornIcon.svg';
import cornGreen from 'assets/images/Corn_Green.svg';
import cottonGreenIcon from 'assets/images/CottonGreen.svg';
import cottonGreyIcon from 'assets/images/CottonIcon.svg';
import cottonGreen from 'assets/images/Cotton_Green.svg';
import hybridBarleyGreyIcon from 'assets/images/HybridBarleyIcon.svg';
import hybridBarleyGreen from 'assets/images/HybridBarley_Green.svg';
import hybridBarleyGreenIcon from 'assets/images/Hybrid_BarleyGreen.svg';
import soyGreenIcon from 'assets/images/SoybeanGreen.svg';
import soyGreyIcon from 'assets/images/SoybeanIcon.svg';
import soyGreen from 'assets/images/Soybean_Green.svg';
import sunflowerGreenIcon from 'assets/images/SunflowerGreen.svg';
import sunflowerGreyIcon from 'assets/images/SunflowerIcon.svg';
import sunflowerGreen from 'assets/images/Sunflower_Green.svg';
import wheatGreyIcon from 'assets/images/WheatIcon.svg';
import wheatGreen from 'assets/images/Wheat_Green.svg';
import cornGreenIcon from 'assets/images/corngreen.svg';
import wheatGreenIcon from 'assets/images/wheatgreen.svg';
import { getFromSessionStorage } from 'core/utils/sessionStorage';
import currency from 'currency.js';
import moment from 'moment';
import React from 'react';
import info from '../../assets/images/info.svg';
const { Title } = Typography;
export const O_AUTH = {
  responseCode: 'code',
  clientId: 'strix-ui',
};

export enum UnitOfMeasures {
  Acres = 'Acres',
  Hectares = 'Hectares',
}

export const getCurrencyUnit = () =>
  getFromLocalStorage('onboarded_country_currency')
    ? getFromLocalStorage('onboarded_country_currency') ?? {}
    : {
        RUB: 'RUB',
      };

export const Currency = getCurrencyUnit();

export enum DecimalFormat {
  CommaAsDecimal = 'CommaAsDecimal',
  PointAsDecimal = 'PointAsDecimal',
}

export enum BusinessUnits {
  Seeds = 'Seeds (SE)',
  CP = 'Crop Protection (CP)',
}

export enum SeasonStatus {
  Draft = 'Draft',
  Submitted = 'Submitted',
}

export enum ProgramTypes {
  LOYALTYPOINTS = 'Loyalty Points',
  GROWTH = 'Growth',
  PRODUCTGROWTH = 'Product Growth',
  AGROBONUS = 'Agrobonus',
}

export enum Crops {
  CORN = 'Corn',
  SOY = 'Soy',
  WHEAT = 'Wheat',
  COFFEE = 'Coffee',
  COTTON = 'Cotton',
  SUGAR = 'Sugar',
  MillingWheat = 'Milling Wheat',
  HYBRID_BARLEY = 'Hybrid Barley',
  SUNFLOWER = 'Sunflower',
  CANOLA_RAPESEED = 'Canola/Rapeseed',
}

export const cropIconType = {
  CAMPAIGN: 'campaign',
  OTHER: 'other',
};

export const cropIconColor = {
  GREEN: 'green',
  GREY: 'grey',
};

export const cropIcons = {
  other: {
    green: {
      [Crops.CORN]: cornGreen,
      [Crops.WHEAT]: wheatGreen,
      [Crops.SOY]: soyGreen,
      [Crops.HYBRID_BARLEY]: hybridBarleyGreen,
      [Crops.COTTON]: cottonGreen,
      [Crops.CANOLA_RAPESEED]: canolaRapeseedGreenIcon,
      [Crops.SUNFLOWER]: sunflowerGreen,
      [Crops.MillingWheat]: wheatGreen,
    },
    grey: {
      [Crops.CORN]: cornGreyIcon,
      [Crops.WHEAT]: wheatGreyIcon,
      [Crops.SOY]: soyGreyIcon,
      [Crops.HYBRID_BARLEY]: hybridBarleyGreyIcon,
      [Crops.COTTON]: cottonGreyIcon,
      [Crops.CANOLA_RAPESEED]: canolaRapeseedGreyIcon,
      [Crops.SUNFLOWER]: sunflowerGreyIcon,
      [Crops.MillingWheat]: wheatGreyIcon,
    },
  },
  campaign: {
    grey: {
      [Crops.CORN]: cornGreyIcon,
      [Crops.WHEAT]: wheatGreyIcon,
      [Crops.SOY]: soyGreyIcon,
      [Crops.HYBRID_BARLEY]: hybridBarleyGreyIcon,
      [Crops.COTTON]: cottonGreyIcon,
      [Crops.CANOLA_RAPESEED]: canolaRapeseedGreyIcon,
      [Crops.SUNFLOWER]: sunflowerGreyIcon,
      [Crops.MillingWheat]: wheatGreyIcon,
    },
    green: {
      [Crops.CORN]: cornGreenIcon,
      [Crops.WHEAT]: wheatGreenIcon,
      [Crops.SOY]: soyGreenIcon,
      [Crops.HYBRID_BARLEY]: hybridBarleyGreenIcon,
      [Crops.COTTON]: cottonGreenIcon,
      [Crops.CANOLA_RAPESEED]: canolaRapeseedGreenIcon,
      [Crops.SUNFLOWER]: sunflowerGreenIcon,
      [Crops.MillingWheat]: wheatGreenIcon,
    },
  },
};

export enum PriceFixationLimit {
  Once = 'Once',
  Multiple = 'Multiple',
}

export enum PricePerUnit {
  USCentsPerBushel = 'USCentsPerBushel',
  USDPerTon = 'USDPerTon',
  EURPerTonne = 'EURPerTonne',
  USDPerBag60kg = 'USDPerBag60kg',
  USCentsPerPound = 'USCentsPerPound',
  RUBPerTon = 'RUBPerTon',
  AUDPerTon = 'AUDPerTon',
  BRLPerTon = 'BRLPerTon',
}

export enum MaturityOptions {
  JAN = 'Jan',
  FEB = 'Feb',
  MARCH = 'March',
  APRIL = 'April',
  MAY = 'May',
  JUNE = 'June',
  JULY = 'July',
  AUGUST = 'Aug',
  SEPT = 'Sept',
  OCTOMBER = 'Oct',
  NOVEMBER = 'Nov',
  DECEMBER = 'Dec',
}

export enum ExchangeFuturePrice {
  CBOT = 'CBOT',
  B3 = 'B3',
  Euronext = 'MATIF', // Exchange should be same as barchart exchange
  Dalian = 'Dalian',
  ICE = 'ICE',
}

export enum ExchangeFuturePriceC3 {
  C3 = 'C3',
}

export enum CommodityDataSource {
  Barchart = 'Barchart',
  C3 = 'C3',
}

export enum HedgingInstrumentType {
  PutSpread = 'PutSpread',
  CallSpread = 'CallSpread',
  QuantoPutSpreadRUBorEUR = 'QuantoPutSpreadRUBorEUR',
  QuantoPutSpreadRUBorUSD = 'QuantoPutSpreadRUBorUSD',
}

export enum TimeZone {
  MoscowTime = 'MoscowTime',
  CentralEuropeanTime = 'CentralEuropeanTime',
  EasternEuropeanTime = 'EasternEuropeanTime',
  EasternEuropeanSummerTime = 'EasternEuropeanSummerTime',
  EasternStandardTimeUS = 'EasternStandardTimeUS',
  CentralUS = 'CentralUS',
  SaoPauloR = 'SaoPauloR',
  BuenosAires = 'BuenosAires',
  USCST = 'USCST',
}

export enum SettlementType {
  EuropeanwithFixedSettlement = 'EuropeanwithFixedSettlement',
  AsianSettlement = 'AsianSettlement',
  AsianSettlementOption = 'AsianSettlementOption',
  ChoiceOfEuropeanAndAsian = 'ChoiceOfEuropeanAndAsian',
  EuropeanwithFixedSettlementOption = 'EuropeanwithFixedSettlementOption',
}

export enum DateFormat {
  DDMMYYYY = 'DDMMYYYY',
  MMDDYYYY = 'MMDDYYYY',
}

export const getFormattedHedgingInstrumentType = (planType: string, t) =>
  ({
    [HedgingInstrumentType.PutSpread]: t('Put Spread'),
    [HedgingInstrumentType.CallSpread]: t('Call Spread'),
    [HedgingInstrumentType.QuantoPutSpreadRUBorEUR]: t('Quanto Call + Put Spreads RUB / USD'),
    [HedgingInstrumentType.QuantoPutSpreadRUBorUSD]: t('Quanto Call + Put Spreads RUB / EUR'),
  }[planType] || planType);

export const getFormattedPricePerUnit = (planType: string, t) =>
  ({
    [PricePerUnit.USCentsPerBushel]: t('US ₵/BU'),
    [PricePerUnit.USDPerTon]: t('$/TON'),
    [PricePerUnit.EURPerTonne]: t('€/TON'),
    [PricePerUnit.USDPerBag60kg]: t('$/bag (60kg)'),
    [PricePerUnit.USCentsPerPound]: t('US ₵/Pound'),
    [PricePerUnit.RUBPerTon]: t('RUB/TON'),
    [PricePerUnit.AUDPerTon]: t('AUD/TON'),
    [PricePerUnit.BRLPerTon]: t('BRL/TON'),
  }[planType] || planType);

export const getFormattedUnit = (planType: string, t) =>
  ({
    [PricePerUnit.USCentsPerBushel]: t('₵/BU'),
    [PricePerUnit.USDPerTon]: t('$/TON'),
    [PricePerUnit.EURPerTonne]: t('€/TON'),
    [PricePerUnit.USDPerBag60kg]: t('$/bag'),
    [PricePerUnit.USCentsPerPound]: t('₵/Pound'),
    [PricePerUnit.RUBPerTon]: t('₽/TON'),
  }[planType] || planType);

export const getFormattedDecimalFormat = (type: string, t) =>
  ({
    [DecimalFormat.CommaAsDecimal]: t('With comma as Decimal'),
    [DecimalFormat.PointAsDecimal]: t('With point as Decimal'),
  }[type] || type);

export const getFormattedSettlementType = (type: string, t) =>
  ({
    [SettlementType.EuropeanwithFixedSettlement]: t('European with Fixed Settlement'),
    [SettlementType.ChoiceOfEuropeanAndAsian]: t('European with Open Settlement'),
    [SettlementType.AsianSettlement]: t('Asian'),
    [SettlementType.AsianSettlementOption]: t('Asian Settlement'),
    [SettlementType.EuropeanwithFixedSettlementOption]: t('European Fixed Settlement'),
  }[type] || type);

export const getFormattedTimeZone = (t, timeZone) => {
  try {
    const time_zone: ITimeZone = JSON.parse(timeZone);
    return t(time_zone?.formatted_label) || t('Moscow Time');
  } catch (error) {
    const timeZoneDetails = getFromSessionStorage('timezone_details');
    if (timeZoneDetails?.length && timeZone) {
      const zone = timeZoneDetails?.find((item) => item?.label === timeZone);
      return t(zone?.formatted_label) || t('Moscow Time');
    }
    return t('Moscow Time');
  }
};

export const getUnitOfMeasuresVolume = (type: string, t) =>
  ({
    [UnitOfMeasuresVolume.Gallons]: t('Gallons'),
    [UnitOfMeasuresVolume.Liters]: t('Liters'),
    [UnitOfMeasuresVolume.Kilos]: t('Kilos'),
    [UnitOfMeasuresVolume.Pounds]: t('Pounds'),
    [UnitOfMeasuresVolume.Packs]: t('Packs'),
    [UnitOfMeasuresVolume.Bags]: t('Bags'),
    // [UnitOfMeasuresVolume.Acres]: t('Acres'),
    // [UnitOfMeasuresVolume.Hectares]: t('Hectares'),  // CHZU-1555: Philippe told to delete Acres, Hectares and USD from Units of Measure Volume So commenting these three Measurements
    // [UnitOfMeasuresVolume.USD]: t('USD'),
    [UnitOfMeasuresVolume.Units]: t('Units'),
    [UnitOfMeasuresVolume.Big_Bags]: t('Big Bags'),
  }[type] || type);

export const getFormattedDateFormat = (type: string, t) =>
  ({
    [DateFormat.DDMMYYYY]: t('DD.MM.YYYY'),
    [DateFormat.MMDDYYYY]: t('MM.DD.YYYY'),
  }[type] || type);

export enum CustomerStatus {
  Customer_NonValidated = 'NOT VALIDATED',
  Customer_Validated = 'VALIDATED',
  Registration_Submitted = 'SUBMITTED',
  Registration_Rejected = 'REJECTED',
  Registration_Approved = 'APPROVED',
  Actual_Purchases_Draft = 'DRAFT',
  Actual_Purchases_Approved = 'APPROVED',
  Price_Fixation_Admin_Processed = 'COMPLETED',
  NO_PAYOUT = 'NO PAYOUT',
  PAYOUT_COMPLETED = 'PAYOUT',
  Price_Fixation_Admin_Addendum_Draft = 'DRAFT',
  ADDENDUM2_DRAFT = 'DRAFT',
  REJECTED = 'REJECTED',
}

export enum StageTypes {
  REGISTRATION = 'Registration',
  PRICE_FIXING = 'PriceFixing',
  PAYOUT = 'Payout',
  PLANSELECTION = 'PlanSelection',
  ACTUALPURCHASES = 'ActualPurchases',
  PRICE_FIX = 'PRICE FIX', //It's same as PriceFixing, it's used in customer landing screen
}

export enum SortColumnTitle {
  Stage = 'Stage',
}

export enum ClassNames {
  REGISTER_TEXT = 'registerText',
  VALIDATE_TEXT = 'validateText',
  NOTVALIDATE_TEXT = 'NotValidateText',
  SUBMITTED_TEXT = 'submittedText',
  REJECTED_TEXT = 'rejectedText',
  APPROVED_TEXT = 'approvedText',
  ACTUAL_PURCHASES_TEXT = 'actualPurchases',
  PRICE_FIX_TEXT = 'priceFixText',
  PAYOUT = 'payoutText',
}
export enum CountryNames {
  US = 'us',
  Russia = 'ru',
  Argentina = 'ar',
  Romania = 'ro',
}

export enum ModalTypes {
  US_MODAL = 'US_MODAL',
  RU_MODAL = 'RU_MODAL',
}

export enum ValidationType {
  campaignSelection = 'campaign_selection',
  TotalMinimumCriteria = 'total_minimum_criteria',
}

export enum StatusTabTypes {
  REGISTRATION = 'REGISTRATION',
  REGISTRATION_RU = 'REGISTRATION_RU',
  SEASON_PURCHASE = 'SEASON_PURCHASE',
  COMMITMENT = 'COMMITMENT',
  PAYOUT = 'payout',
  PAYOUT_RU = 'PAYOUT_RU',
  RISK_PROTECTED = 'RISK_PROTECTED',
}
export enum PhoneNumberType {
  US = '(270) 435-4369',
  AR = '+549 1164395055',
  RO = '+ 40748126838',
}

export enum EmailType {
  RU = 'fin.solutions@syngenta.com',
  US = 'support@agconnections.com',
  AR = 'ignacio.galazi@syngenta.com',
  RO = 'gabriela.dragos@syngenta.com',
}

export enum CropType {
  Wheat = 'Wheat',
  Corn = 'Corn',
  Soyabean = 'Soybean',
  Hybrid_Barley = 'Hybrid Barley',
  Sunflower = 'Sunflower',
  Cotton = 'Cotton',
  Canola_Rapeseed = 'Canola/Rapeseed',
}

export enum CropConvertionRate {
  CornBushelsPerMetricTon = 39.368,
  SoyBushelPerMetricTon = 36.744,
}

export const getSupportNumber = () => {
  const countryCode = getCountryCode();
  const CountryCodes = countryCodesFunc();
  switch (countryCode) {
    case CountryCodes.US:
      return PhoneNumberType.US;

    case CountryCodes.Argentina:
      return PhoneNumberType.AR;

    case CountryCodes.Romania:
      return PhoneNumberType.RO;

    default:
      return PhoneNumberType.AR;
  }
};

export const getSupportEmail = () => {
  const countryCode = getCountryCode();
  const CountryCodes = countryCodesFunc();

  switch (countryCode) {
    case CountryCodes.Argentina:
      return EmailType.AR;

    case CountryCodes.Romania:
      return EmailType.RO;

    default:
      return EmailType.AR;
  }
};

export const getImpliedUsage = (record: any, decimal_format: any, isActuals?: boolean) => {
  const calculatedUsage = currency(isActuals ? record?.actual_volume : record?.volume).divide(
    record.usage
  );
  return calculatedUsage && isNaN(calculatedUsage?.value) ? '' : Math.round(calculatedUsage.value);
};

export enum UnitOfMeasuresVolume {
  // Acres = 'Acres',         // CHZU-1555: Philippe told to delete Acres, Hectares and USD from Units of Measure Volume So commenting these three Measurements
  // Hectares = 'Hectares',
  Gallons = 'Gallons',
  Bags = 'Bags',
  Units = 'Units',
  // USD = 'USD',
  Packs = 'Packs',
  Liters = 'Liters',
  Pounds = 'Pounds',
  Kilos = 'Kilos',
  Big_Bags = 'Big Bags',
}
export const calculateProductVolume = (
  product: any,
  campaignUnitsMeasureVolume: any,
  unitConversionData: any
) => {
  if (product?.uom !== campaignUnitsMeasureVolume) {
    const value = unitConversionData?.data?.find(
      (item) => item?.from_unit === product?.uom && item?.to_unit === campaignUnitsMeasureVolume
    ).to_value;
    return Number(product?.volume * value);
  } else {
    return Number(product?.volume);
  }
};

export const getCropYears = () => {
  const cropYears = ['2021-22', '2022-23', '2023-24'];
  return cropYears;
};

export const getFutureYears = () => {
  const futureYears = [
    '2021',
    '2022',
    '2023',
    '2024',
    '2025',
    '2026',
    '2027',
    '2028',
    '2029',
    '2030',
  ];
  return futureYears;
};

export const ALL = 'ALL';

export enum ProductType {
  Herbicides_Growth_Regulator = 'Herbicides/Growth_Regulator',
  Fungicides_Packs = 'Fungicides (Pack)',
  Fungicides = 'Fungicides',
  Herbicides = 'Herbicides',
  Biostimulant = 'Biostimulant',
  Seed_Treatment = 'Seed_Treatment',
  Insecticides = 'Insecticides',
  Seeds = 'Seeds',
  Seed_Treatment_Fungicides = 'Seed_Treatment/Fungicides',
  Selective_Herbicides = 'Selective_Herbicides',
  Non_Selective_Herbicides = 'Non_Selective_Herbicides',
  Growth_Regulator = 'Growth_Regulator',
  Fungicides_Growth_Regulator = 'Fungicides/Growth_Regulator',
  SY_Corn = 'SY Corn',
  Nutritional = 'Nutritional',
  Post_Herbicide = 'Post Herbicide',
  SY_Rapeseed = 'SY Rapeseed',
  SY_Barley = 'SY Barley',
  SY_Sunflower = 'SY Sunflower',
  Herbicides_Insecticides = 'Herbicides/Insecticides',
  Biostimulants_Fungicides = 'Biostimulants/Fungicides',
}

export const getCurrency = (currencyUnit, type) => {
  const country = countryDetailsFromStorage();
  return country ? (type === 'Symbol' ? `${country.currency_symbol}{" "}` : country.currency) : '';
};

export enum SSRTypes {
  RetailRepresentative = 'Retail Representative',
}

export const defaultSSRTypeData = (ssrTypeData) =>
  ssrTypeData?.data?.find((el) => el.name == SSRTypes.RetailRepresentative)?.name;

export const getConversionRate = (cropName) => {
  // find the crop specific  conversion rate
  const conversionRate: any =
    cropName === CropType.Corn
      ? CropConvertionRate.CornBushelsPerMetricTon
      : CropConvertionRate.SoyBushelPerMetricTon;
  return conversionRate;
};

export const convertValueWithConversionRate = (value: number, cropName: string) =>
  (value * getConversionRate(cropName)) / 100;

export const RevertConvertedValueWithConversionRate = (value: number, cropName: string) =>
  (value * 100) / getConversionRate(cropName);

export const getFixedPrice = (campaignSettings, fixedPrice, conversionRate) => {
  const barchartIndex = campaignSettings['price_per_unit_underlying_index'];
  const barchartConversion = campaignSettings['price_per_unit_conversion'];
  let PriceFixedValue;
  if (
    barchartIndex === PricePerUnit.EURPerTonne &&
    barchartConversion === PricePerUnit.EURPerTonne
  ) {
    // for wheat crop: No need to conert as both the currencies are same.
    PriceFixedValue = fixedPrice;
  } else if (
    barchartIndex === PricePerUnit.USCentsPerBushel &&
    barchartConversion === PricePerUnit.USDPerTon
  ) {
    /* for corn and soy crop: need to conert as both the currencies are not same. */
    PriceFixedValue = (fixedPrice * conversionRate) / 100;
  } else {
    // FOR ANY INCORRECT SELECTION FOR CURRENCYPURCHASES AND PRICEPERUNITCONVERSION
    PriceFixedValue = fixedPrice;
  }

  return PriceFixedValue;
};

export enum NotionalUnits {
  Tons = 'Tons',
  Bushels = 'Bushels',
}
export const getPriceFixedDate = (obj) =>
  obj?.price_fix_start_date &&
  obj?.price_fix_last_date &&
  moment(obj?.price_fix_start_date).isSame(moment(obj?.price_fix_last_date))
    ? `${formatDate(obj?.price_fix_start_date, obj?.date_format, obj?.time_zone)}`
    : `${formatDate(obj?.price_fix_start_date, obj?.date_format, obj?.time_zone)}-${formatDate(
        obj?.price_fix_last_date,
        obj?.date_format,
        obj?.time_zone
      )}`;

export const getSettlementDate = (obj) =>
  obj?.settlement_type === SettlementType.EuropeanwithFixedSettlement && obj?.european_date
    ? `${formatDate(obj?.european_date, obj?.date_format, obj?.time_zone)}`
    : `${formatDate(obj?.payout_start_date, obj?.date_format, obj?.time_zone)}-${formatDate(
        obj?.payout_end_date,
        obj?.date_format,
        obj?.time_zone
      )}`;

export const getPriceFixedPDFName = (activeCropData) => {
  const settlementDate =
    activeCropData?.campaignSettings?.settlement_type ===
      SettlementType.EuropeanwithFixedSettlement && activeCropData?.campaignSettings?.european_date
      ? `${activeCropData?.campaignSettings?.european_date}`
      : `${activeCropData?.campaignSettings?.payout_start_date} ${activeCropData?.campaignSettings?.payout_end_date}`;
  return `${activeCropData?.cropData?.exchange} ${activeCropData?.cropData?.crop_name} ${activeCropData?.cropData?.future_year} ${activeCropData?.cropData?.maturity} ${activeCropData?.campaignSettings?.price_fix_start_date} ${activeCropData?.campaignSettings?.price_fix_last_date} ${settlementDate}`;
};

export const getIndexWiseTabTitle = (key, obj, t) => {
  const formattedKey = key?.split(' ')?.slice(0, 4)?.toString().replace(/,/g, ' ');
  return (
    <>
      <Typography>
        {formattedKey}
        <Tooltip
          title={`
            ${t('Index with Lock in date as')}
            ${getPriceFixedDate(obj?.[1]?.campaignSettings)}                       
            ${t('& Fixed Settlement date as')}
            ${getSettlementDate(obj?.[1]?.campaignSettings)}
            `}
          placement="bottomLeft"
        >
          <img src={info} alt="Info" style={{ paddingLeft: '10px' }} />
        </Tooltip>
      </Typography>
      <Title level={5}>
        ({getPriceFixedDate(obj?.[1]?.campaignSettings)} |{' '}
        {getSettlementDate(obj?.[1]?.campaignSettings)})
      </Title>
    </>
  );
};

export const getIndexName = (key, obj, page) => {
  const formattedKey = key?.split(' ')?.slice(0, 4)?.toString().replace(/,/g, ' ');
  return page === 'FinalExchangeRate'
    ? `${formattedKey} (${getPriceFixedDate(obj?.campaignSettings)} | ${getSettlementDate(
        obj?.campaignSettings
      )})`
    : `${formattedKey} (${getPriceFixedDate(obj?.[1]?.campaignSettings)} | ${getSettlementDate(
        obj?.[1]?.campaignSettings
      )})`;
};

export const graphTabs = [
  { name: '1 Week', key: '1' },
  { name: '1 Month', key: '2' },
  { name: '6 Months', key: '3' },
  { name: '1 Year', key: '4' },
];
export const enableDisableSubmitPriceFixBtn = (currentStageData, activeSeason) => {
  let disablePriceFix = false;
  let isPriceFixLastDateCutOffTime = false;
  if (currentStageData && activeSeason && activeSeason?.price_fix_last_date) {
    //Case 1: If grouped LE and Bonus agreement is not yet approved for all the LE's => Disable Button
    const current_date = moment().tz(getTimeZone(activeSeason?.time_zone));
    const price_fix_last_date = moment(activeSeason?.price_fix_last_date).tz(
      getTimeZone(activeSeason?.time_zone)
    );
    const price_fix_start_date = moment(activeSeason?.price_fix_start_date).tz(
      getTimeZone(activeSeason?.time_zone)
    );

    //Case 1: Grouped LE and Individual LE:
    if (current_date.isBefore(price_fix_start_date) || current_date.isAfter(price_fix_last_date)) {
      disablePriceFix = true;
    } else if (
      currentStageData?.data?.[0]?.is_group_tin &&
      !currentStageData?.data?.[0]?.allow_price_fix_grouptin
    ) {
      //Case 2: Grouped LE:
      disablePriceFix = true;
    }
    //Grouped LE and Individual LE:

    //Case 3: If Price fixation Last date and current date is same
    else if (current_date.isSame(price_fix_last_date, 'date')) {
      const grower_price_fixation_cutoff_time = '15:30';
      const current_time = moment(current_date, 'HH:mm');
      // To check if Current time is after grower_price_fixation_cutoff_time (1PM MSK) => Disable Button
      // Individual LE & Grouped LE: System will auto process price fixation for pending requests who's BA has been approved by admin
      if (current_time.isAfter(moment(grower_price_fixation_cutoff_time, 'HH:mm')))
        isPriceFixLastDateCutOffTime = true;
      else isPriceFixLastDateCutOffTime = false;
    }
  }
  return { disablePriceFix, isPriceFixLastDateCutOffTime };
};

// On Actual Purchases Tab Edit button will be enable based on the pricefix last Date
// This function will return true when current date is passes price fix date
export const enableDisableActualsEditBtn = (season) => {
  let disableEditBtn = false;
  if (season && season?.price_fix_last_date) {
    const currentDate = moment().tz(getTimeZone(season?.time_zone));
    const priceFixLastDate = moment(season?.price_fix_last_date).tz(getTimeZone(season?.time_zone));

    if (currentDate.isAfter(priceFixLastDate)) {
      disableEditBtn = true;
    }
  }
  return disableEditBtn;
};

export enum CountryOnboardedStatus {
  DRAFT = 'Draft',
  SUBMITTED = 'Submitted',
}

export enum ButtonType {
  save = 'save',
  submit = 'submit',
  editSubmit = 'editSubmit',
  done = 'done',
}

export enum CountryConfigKeys {
  UNIT_AND_FORMATS = 'UNIT_AND_FORMATS',
  PAYMENT_OPTION = 'PAYMENT_OPTION',
  SUPPORT_CONTACT_INFO = 'SUPPORT_CONTACT_INFO',
  CAMPAIGN_SELECTION_INFO_TEXT = 'CAMPAIGN_SELECTION_INFO_TEXT',
  FAQ_VIDEO = 'FAQ_VIDEO',
  FAQ_QUESTION = 'FAQ_QUESTION',
  LANDING_SCREEN = 'LANDING_SCREEN',
  CAMPAIGN_SELECTION_CRITERIA = 'CAMPAIGN_SELECTION_CRITERIA',
  INSTRUCTIONS_FOR_GROWER_SIGNUP = 'INSTRUCTIONS_FOR_GROWER_SIGNUP',
}

export enum pageNames {
  MyCampaign = 'my-campaign',
  NEWS = 'news',
}

export enum BusinessUnit {
  CP = 'CP',
  SE = 'SE',
}

export enum contractsTypes {
  PRIVACY_POLICY = 'Privacy_Policy',
  PROGRAM_TERMS = 'Program_Terms',
  TERMS_AND_CONDITIONS = 'Terms_And_Conditions',
  TRAINING_DOCUMENTS = 'Training_Documents',
}

export const getDateRange = (
  start_date: string,
  end_date: string,
  date_format: DateFormat,
  time_zone: any
) =>
  start_date && end_date && moment(start_date).isSame(moment(end_date))
    ? `${formatDate(start_date, date_format, time_zone)}`
    : `${formatDate(start_date, date_format, time_zone)} - ${formatDate(
        end_date,
        date_format,
        time_zone
      )}`;

export enum searchType {
  ICS = 'ics',
}

export enum UPLOAD_FILE_TYPE {
  TinUpdateProof = 'TinUpdateProof',
}

export enum PayoutOptions {
  payout = 'payout',
  no_payout = 'no payout',
}

export enum GROWER_ENROLLMENT_STEPS {
  CAMPAIGN_SELECTION = 'CAMPAIGN_SELECTION',
  GROWER_ENROLLMENT = 'GROWER_ENROLLMENT',
  COMPANY_DETAILS = 'COMPANY_DETAILS',
  BANK_DETAILS = 'BANK_DETAILS',
  AUTHORIZATION_RIGHTS = 'AUTHORIZATION_RIGHTS',
  STATISTICAL_FORMS = 'STATISTICAL_FORMS',
}
export enum PhoneNumberLength {
  MIN_LENGTH = 4,
  MAX_LENGTH = 25,
}
